import { Component, ChangeDetectionStrategy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatMenuTrigger, MAT_MENU_DEFAULT_OPTIONS } from '@angular/material/menu';
import { DropDownActionButton } from '../../interfaces/drop-down-action-button.interface';
import { ActionButton } from '../../interfaces/action-button.interface';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'overlay-mobile-menu' },
    },
  ],
})
export class ActionButtonsComponent {
  @Input() dropDownButton!: DropDownActionButton[];
  @Input() blueButton!: ActionButton | undefined;
  @Input() blackButton!: ActionButton | undefined;
  @Input() whiteButton!: ActionButton | undefined;
  @Output() buttonClicked: EventEmitter<string | (() => void) | null> = new EventEmitter();
  @ViewChild(MatMenuTrigger) menu!: MatMenuTrigger;
  public isString(val: string | (() => void) | null): boolean {
    return typeof val === 'string';
  }

  // constructor() {}

  public closeMenu(): void {
    this.menu.closeMenu();
  }
}
