<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions [align]="'end'">
  <button mat-button (click)="reject()">{{ data.buttonKo || 'Reject' }}</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
    {{ data.buttonOk || 'Yes, proceed' }}
  </button>
</div>
